/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: juliette-font;
  src: url('/assets/font/Juliette.ttf');
}

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=PT+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
//@import '~ngx-toastr/toastr';

$dark-color: #262a27;
$primary-color: #70d86f;
$brown-color: #a48d6c;
$beige-color: #e6ded4;


$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1400px;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.carousel{
  .carousel-inner{
    .carousel-item {
      display: block !important;
      z-index: 1;
      position: absolute;
      opacity: 0;
      transition: opacity 2s ease, visibility 1s;
      visibility: hidden;
    }
    .carousel-item.active {
      position: relative;
      opacity: 1;        
      transition: opacity 2s ease, visibility 1s;
      visibility: visible;
      
    }
  }
}

html {
  scroll-behavior: smooth;
}

h2 {
  font-family: lora, serif;
  font-size: 2.6rem;
  margin-bottom: 30px;
}

.btn {
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 12px 20px;
  margin-top: 15px;

  &:active,
  &:focus {
    border-color: none !important;
    box-shadow: none !important;
    outline: 0 none !important;
  }
}

.btn-primary {
  border: 1px solid $primary-color;
  color: $primary-color;
  background-color: transparent;

  &:hover {
    color: white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
  }

  &:active,
  &:focus {
    background-color: darken($primary-color, 10) !important;
    border: 1px solid darken($primary-color, 10) !important;
  }
}